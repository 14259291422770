import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Peer2Pay from '../components/Deposit/Peer2Pay'
import BankQrCode from '../components/Deposit/BankQrCode'
import BankQrCodeOutTime from '../components/Deposit/BankQrCodeOutTime'
import TruewalletQRDialog from '../components/Deposit/TruewalletQRDialog'
import TruewalletVoucher from '../components/Deposit/TruewalletVoucher'
import UploadSlip from '../components/Deposit/UploadSlip'
import BankDeposit from './BankDeposit';
import WalletDeposit from './WalletDeposit'
import DepositMethodButton from '../components/Deposit/DepositMethodButton'
import PromotionSlides from '../components/Deposit/PromotionSlides'
import { makePath } from '../helpers/path'
import { ApplicationState } from '../store'
import { requestDepositTo } from '../store/deposit/deposit.actions'
import { setUser } from '../store/user/user.actions'
import AuthService from '../services/auth'
import BankDepositTabs from '../components/Deposit/DepositTabs/BankDepositTabs'
import BankQrCodeTabs from '../components/Deposit/DepositTabs/BankQrCodeTabs'
import BankQrCodeOutTimeTabs from '../components/Deposit/DepositTabs/BankQrCodeOutTimeTabs'
import Peer2PayTabs from '../components/Deposit/DepositTabs/Peer2PayTabs'
import WalletDepositTabs from '../components/Deposit/DepositTabs/WalletDepositTabs'
import TruewalletQRDialogTabs from '../components/Deposit/DepositTabs/TruewalletQRDialogTabs'
import TruewalletVoucherTabs from '../components/Deposit/DepositTabs/TruewalletVoucherTabs'
import { Col, Divider, Row, Tabs } from 'antd'
import { Skeleton } from 'antd';
import dayjs from 'dayjs';

import { useAppContext } from '../contexts/AppContext';
import { vizpayOrderInquiry } from '../@types/vizpay'
import { toast } from 'react-toastify'

const Deposit = () => {
	const prefix = useSelector((state: ApplicationState) => state.prefix.info)
	const depositState = useSelector((state: ApplicationState) => state.deposit)
	const userState = useSelector((state: ApplicationState) => state.user)
	const info = userState.info

	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(requestDepositTo())
	}, [dispatch])

	const isBankEnable = depositState.depositToList.filter(({ bank }) => bank !== 'truewallet').length > 0
	const isTruewalletEnable = depositState.depositToList.filter(({ bank }) => bank === 'truewallet').length > 0
	const isVizpayStatus = prefix?.functions?.vizpay;
	const isPeer2PayStatus = prefix?.peer2pay.enable;
	const [openBankDeposit, setOpenBankDeposit] = useState(false)
	const [openBankQrCode, setOpenBankQrCode] = useState(false)
	const [openBankQrCodeOutTime, setOpenBankQrCodeOutTime] = useState(false)
	const [openWalletDeposit, setOpenWalletDeposit] = useState(false)
	const [openTruewalletQRDialog, setOpenTruewalletQRDialog] = useState(false)
	const [openTruewalletVoucher, setOpenTruewalletVoucher] = useState(false)
	const [openUploadSlip, setOpenUploadSlip] = useState(false)
	const [openPeer2Pay, setOpenPeer2Pay] = useState(false)

	// ตัวอย่างเวลาเริ่มต้นและสิ้นสุด
	const startTime = '22:50';
	const endTime = '02:00';

	// แปลงเวลาเริ่มต้นและสิ้นสุดเป็นออบเจ็กต์ Day.js
	const start = dayjs().set('hour', parseInt(startTime.split(':')[0])).set('minute', parseInt(startTime.split(':')[1]));
	const end = dayjs().set('hour', parseInt(endTime.split(':')[0])).set('minute', parseInt(endTime.split(':')[1]));

	// เช็คว่าเวลาปัจจุบันอยู่ระหว่างช่วงเวลาหรือไม่ (แบบข้ามวัน)
	const isBetweenCrossDay = dayjs().isAfter(start) || dayjs().isBefore(end);
	const { setIsOpenRedeemDialog } = useAppContext();

	const textFreeCredit = `ทุกยอดฝาก รับฟรีเพิ่ม ${prefix?.peer2pay.amountBonus}${prefix?.peer2pay.typeBonus === "percent" ? "%" : " บาท"}`;
	const isPeer2payFreeCredit = prefix?.peer2pay.amountBonus === 0 ? "ฝากถอน 1 วินาที ฟรีค่าธรรมเนียม" : textFreeCredit;

	// const [fetching, setFetching] = useState(false)

	// const refetchCredit = async () => {
	// 	if (fetching) {
	// 		return
	// 	}
	// 	setFetching(true)
	// 	try {
	// 		const res = await AuthService.getMemberInfo(true)
	// 		dispatch(setUser(res))
	// 	} catch ({ message }: any) {
	// 		toast.error(message as string)
	// 	} finally {
	// 		setFetching(false)
	// 	}
	// }
	// const [itemDepositTabs, setItemDepositTabs] = useState<any>([]);
	// useEffect(() => {
	// 	// ตั้งค่า itemDepositTabs
	// 	// if (!prefix || !depositState.isLoaded) {
	// 	if (depositState.isLoaded) {
	// 		const listTabs = [
	// 			{
	// 				key: '1',
	// 				label: `ธนาคาร`,
	// 				children: (
	// 					<>
	// 						โอนผ่านธนาคาร
	// 						<div className="child-tab">
	// 							<BankDepositTabs />
	// 						</div>
	// 					</>
	// 				),
	// 				icon: () => (<div className="deposit-method-icon"><img src={'icons/webp/bank.webp'} alt={'bank'} style={{ animation: `dance 5298ms linear infinite` }} /></div>),
	// 				disabled: info?.bankCode !== "TRUEWALLET" && isBankEnable ? false : true,
	// 			},
	// 			{
	// 				key: '2',
	// 				label: `พร้อมเพย์`,
	// 				children: (
	// 					<>
	// 						พร้อมเพย์
	// 						<div className="child-tab">
	// 							{(prefix?.prefix === "ALD88" || prefix?.prefix === "POK9" || prefix?.prefix === "9HENG88") && isBetweenCrossDay ? (
	// 								<BankQrCodeOutTimeTabs />
	// 							) : (
	// 								<BankQrCodeTabs />
	// 							)}
	// 						</div>
	// 					</>
	// 				),
	// 				icon: () => (<div className="deposit-method-icon"><img src={'icons/webp/promtpay.webp'} alt={'qr-code'} style={{ animation: `dance 5698ms linear infinite` }} /></div>),
	// 				disabled: info?.bankCode !== "TRUEWALLET" && isVizpayStatus && typeof isVizpayStatus !== 'undefined' ? false : true,
	// 			},
	// 			{
	// 				key: '3',
	// 				label: `Peer2Pay`,
	// 				children: (
	// 					<>
	// 						<img src="/p2p/p2p-title.webp" width={180} alt="p2p title" />
	// 						<div className="child-tab">
	// 							<Peer2PayTabs subTitle="ฝากเงินรวดเร็ว 1 วินาที"
	// 								textRibbon={isPeer2payFreeCredit} />
	// 						</div>
	// 					</>
	// 				),
	// 				icon: () => (<div className="deposit-method-icon"><img src={'icons/webp/peer2pay.webp'} alt={'peer2pay'} style={{ animation: `dance 4798ms linear infinite` }} /></div>),
	// 				disabled: isPeer2PayStatus ? false : true,
	// 			},
	// 			{
	// 				key: '4',
	// 				label: `ทรูวอลเล็ท`,
	// 				children: (
	// 					<>
	// 						{!depositState.truewalletQREnable && (<>โอนผ่านทรูวอลเล็ท</>)}
	// 						<div className="child-tab">
	// 							{depositState.truewalletQREnable ? (
	// 								<Tabs
	// 									defaultActiveKey="1"
	// 									centered
	// 									items={[
	// 										{
	// 											key: '1',
	// 											label: 'โอนผ่านทรูวอลเล็ท',
	// 											children: <WalletDepositTabs />,
	// 											disabled: isTruewalletEnable ? false : true,
	// 										},
	// 										{
	// 											key: '2',
	// 											label: 'ทรูวอลเล็ท (QR Code)',
	// 											children: <TruewalletQRDialogTabs />,
	// 											disabled: depositState.truewalletQREnable ? false : true,
	// 										},
	// 									].filter(item => !item.disabled)}
	// 								/>
	// 							) : <WalletDepositTabs />}
	// 						</div>
	// 					</>
	// 				),
	// 				icon: () => (<div className="deposit-method-icon"><img src={'icons/webp/true-wallet.webp'} alt={'true-wallet'} style={{ animation: `dance 5598ms linear infinite` }} /></div>),
	// 				disabled: isTruewalletEnable || depositState.truewalletQREnable ? false : true,
	// 			},
	// 			{
	// 				key: '5',
	// 				label: `ทรูวอลเล็ท (อั่งเปา)`,
	// 				children: (
	// 					<>
	// 						ทรูวอลเล็ท (อั่งเปา)
	// 						<div className="child-tab">
	// 							<TruewalletVoucherTabs />
	// 						</div>
	// 					</>
	// 				),
	// 				icon: () => (<div className="deposit-method-icon"><img src={'icons/webp/voucher.webp'} alt={'voucher'} style={{ animation: `dance 7298ms linear infinite` }} /></div>),
	// 				disabled: depositState.truewalletVoucherEnable ? false : true,
	// 			},
	// 			{
	// 				key: '6',
	// 				label: `โค้ดคูปอง`,
	// 				icon: () => (<div className="deposit-method-icon"><img src={'icons/webp/coupon.webp'} alt={'coupon'} style={{ animation: `dance 4998ms linear infinite` }} /></div>),
	// 				disabled: depositState.redeemEnable ? false : true,
	// 			},
	// 		].filter(item => !item.disabled);
	// 		setItemDepositTabs(listTabs);
	// 	}
	// }, [depositState, info, prefix, isBankEnable, isBetweenCrossDay, isVizpayStatus, isTruewalletEnable, isPeer2payFreeCredit, isPeer2PayStatus]);

	// const [activeKey, setActiveKey] = useState('');

	// useEffect(() => {
	// 	if (itemDepositTabs.length > 0) {
	// 		const isActiveKeyDisabled = itemDepositTabs.find((item: any) => item.key === activeKey && item.disabled);
	// 		if (!activeKey || isActiveKeyDisabled) {
	// 			setActiveKey(itemDepositTabs[0].key);
	// 		}
	// 	}
	// }, [itemDepositTabs, activeKey]);

	if (!prefix || !depositState.isLoaded) {
		return (
			<Row justify="center">
				<Col xs={24} md={16} xl={12}>
					<div className="deposit-skeleton">
						<Skeleton.Button active />
						<Skeleton.Button active />
						<Skeleton.Button active />
						<Skeleton.Button active />
					</div>
				</Col>
			</Row>
		)
	}

	return (
		<Fragment>
			<PromotionSlides />
			{/* {prefix?.prefix === "ALD88" ? (
				<div className="deposit-tabs-main">
					<div className="form-card">
						<div className="form-card-body">
							<Tabs
								activeKey={activeKey}
								className="deposit-tabs"
								// defaultActiveKey={activeKey}
								type="card"
								centered
								tabPosition={'top'}
								items={itemDepositTabs.map(({ key, label, icon: Icon, children }: any) => ({
									key,
									label: (
										<span>
											<Icon />
											{label}
										</span>
									),
									children,
								}))}
								destroyInactiveTabPane={true}
								onChange={(e) => {
									if (e !== '6') {
										setActiveKey(e);
									}
									if (e === '3') {
										refetchCredit();
									}
								}}
								onTabClick={(key) => {
									if (key === '6') {
										setIsOpenRedeemDialog(true);
									}
								}}
							/>
						</div>
					</div>
				</div>
			) : ( */}
			<>
				<Row justify="center">
					<Col xs={24} md={16} xl={12}>
						{info?.bankCode !== "TRUEWALLET" && isBankEnable && (
							<div onClick={() => setOpenBankDeposit(true)}>
								<DepositMethodButton
									icon="icons/webp/bank.webp"
									title="โอนผ่านธนาคาร"
								/>
							</div>
						)}

						{prefix?.prefix === "ALD88" || prefix?.prefix === "POK9" || prefix?.prefix === "9HENG88" ? (
							<>
								{info?.bankCode !== "TRUEWALLET" && isVizpayStatus && typeof isVizpayStatus !== 'undefined' && !isBetweenCrossDay && (
									<div onClick={() => setOpenBankQrCode(true)}>
										<DepositMethodButton
											icon="icons/webp/promtpay.webp"
											title="เติมผ่าน พร้อมเพย์"
										/>
									</div>
								)}

								{/* {info?.bankCode !== "TRUEWALLET" && isVizpayStatus && typeof isVizpayStatus !== 'undefined' && isBetweenCrossDay && (
									<div onClick={() => setOpenBankQrCodeOutTime(true)}>
										<DepositMethodButton
											icon="icons/webp/promtpay.webp"
											title="เติมผ่าน พร้อมเพย์"
										/>
									</div>
								)} */}
							</>
						) : (
							info?.bankCode !== "TRUEWALLET" && isVizpayStatus && typeof isVizpayStatus !== 'undefined' && (
								<div onClick={() => setOpenBankQrCode(true)}>
									<DepositMethodButton
										icon="icons/webp/promtpay.webp"
										title="เติมผ่าน พร้อมเพย์"
									/>
								</div>
							)
						)}

						{/* {prefix.peer2pay && (userState?.info?.phone === "0967567844") && ( */}
						{isPeer2PayStatus && (
							<div onClick={() => setOpenPeer2Pay(true)} className="p2p">
								<DepositMethodButton
									icon="icons/webp/peer2pay.webp"
									title="Peer2Pay"
									subTitle="ฝากเงินรวดเร็ว 1 วินาที"
									textRibbon={isPeer2payFreeCredit}
								/>
							</div>
						)}

						{isTruewalletEnable && (
							<div onClick={() => setOpenWalletDeposit(true)}>
								<DepositMethodButton
									icon="icons/webp/true-wallet.webp"
									title="โอนผ่านทรูวอลเล็ท"
								/>
							</div>
						)}

						{depositState.truewalletQREnable && (
							<div onClick={() => setOpenTruewalletQRDialog(true)}>
								<DepositMethodButton
									icon="icons/webp/qr-code.webp"
									title="ทรูวอลเล็ท (QR Code)"
								/>
							</div>
						)}

						{depositState.truewalletVoucherEnable && (
							<div onClick={() => setOpenTruewalletVoucher(true)}>
								<DepositMethodButton
									icon="icons/webp/voucher.webp"
									title="ทรูวอลเล็ท (อั่งเปา)"
								/>
							</div>
						)}

						{depositState.redeemEnable && (
							<div onClick={() => setIsOpenRedeemDialog(true)}>
								<DepositMethodButton
									icon="icons/webp/coupon.webp"
									title="เติมโค้ดคูปอง"
								/>
							</div>
						)}

						{(prefix?.functions.scanqr && info?.bankCode !== "SCB") && (
							<>
								<Divider />
								<Link to={makePath('/deposit/upload-slip')}>
									<DepositMethodButton
										icon="icons/webp/transfer-warning.webp"
										title="อัพโหลดสลิป"
										subTitle="สำหรับผู้ที่โอนเงินแล้วยังไม่เข้าระบบ"
										secondary
									/>
								</Link>
							</>
						)}
					</Col>
				</Row>

				<BankDeposit isOpen={openBankDeposit} onClose={() => setOpenBankDeposit(false)} />
				<Peer2Pay isOpen={openPeer2Pay} onClose={() => setOpenPeer2Pay(false)} onOpen={() => setOpenPeer2Pay(true)} />
				<BankQrCode isOpen={openBankQrCode} onClose={() => setOpenBankQrCode(false)} onOpen={() => setOpenBankQrCode(true)} />
				<BankQrCodeOutTime isOpen={openBankQrCodeOutTime} onClose={() => setOpenBankQrCodeOutTime(false)} onOpen={() => setOpenBankQrCodeOutTime(true)} />
				<WalletDeposit isOpen={openWalletDeposit} onClose={() => setOpenWalletDeposit(false)} />
				<TruewalletQRDialog isOpen={openTruewalletQRDialog} onClose={() => setOpenTruewalletQRDialog(false)} />
				<TruewalletVoucher isOpen={openTruewalletVoucher} onClose={() => setOpenTruewalletVoucher(false)} />
				<UploadSlip isOpen={openUploadSlip} onClose={() => setOpenUploadSlip(false)} />
			</>
			{/* )} */}

		</Fragment >
	)
}

export default Deposit