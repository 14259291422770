import React, { useEffect, useState } from "react"
import axios from "axios"
import { DatePicker, Input, Table } from "antd"
import type { TableProps } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import dayjs from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"
import { format } from '../../helpers/number'

dayjs.extend(customParseFormat)

const { RangePicker } = DatePicker
const { Search } = Input

interface DataType {
    key: string
    phoneNumber: string
    deposit: number
    withdraw: number
    profit: number
    company: number
    netProfit: number
    commission: number
}

const columns: TableProps<DataType>['columns'] = [
    {
        title: 'ผู้เล่น',
        dataIndex: 'phoneNumber',
        render: (text, record) => (
            <div>
                {record.phoneNumber.replace(/-/g, '')}
            </div>
        ),
    },
    {
        title: 'ยอดฝาก',
        dataIndex: 'deposit',
        key: 'deposit',
        render: (text, record) => (
            <div>
                {format(record.deposit)}
            </div>
        ),
    },
    {
        title: 'ยอดถอน',
        dataIndex: 'withdraw',
        key: 'withdraw',
        render: (text, record) => (
            <div>
                {format(record.withdraw)}
            </div>
        ),
    },
    {
        title: 'กำไร',
        key: 'profit',
        render: (text, record) => (
            <div style={{ color: record.deposit - record.withdraw > 0 ? '#17c5a2' : '#ff2c2c' }}>
                {format(record.deposit - record.withdraw)}
            </div>
        ),
    },
    {
        title: 'ยอดบริษัท',
        key: 'company',
        render: (text, record) => (
            <div style={{ minWidth: 90, color: record.deposit - record.withdraw > 0 ? '#17c5a2' : '#ff2c2c' }}>
                {format((record.deposit - record.withdraw) * 0.2)}
            </div>
        ),
    },
    {
        title: 'กำไรสุทธิ',
        key: 'netProfit',
        render: (text, record) => (
            <div style={{ minWidth: 90, color: record.deposit - record.withdraw > 0 ? '#17c5a2' : '#ff2c2c' }}>
                {format((record.deposit - record.withdraw) * 0.8)}
            </div>
        ),
    },
    {
        title: 'คอมมิชชั่น',
        key: 'commission',
        fixed: 'right',
        render: (text, record) => (
            <div style={{ minWidth: 90, color: record.deposit - record.withdraw > 0 ? '#17c5a2' : '#ff2c2c' }}>
                {format(((record.deposit - record.withdraw) * 0.8) * 0.4)}
            </div>
        ),
    }
]

const PartnerReport = () => {
    const dateFormat = "DD/MM/YYYY"
    const [data, setData] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(true)
    const [isLargeScreen, setIsLargeScreen] = useState<boolean>(window.innerWidth > 768)
    const [searchText, setSearchText] = useState<string>('')
    const [filteredData, setFilteredData] = useState<DataType[]>(data)

    const buddhistYear = (date: any) => {
        if (!date) return date
        return date.year(date.year() + 543)
    }

    const gregorianYear = (date: any) => {
        if (!date) return date
        return date.year(date.year() - 543)
    }

    const handleSearch = (e: string) => {
        const value = e
        setSearchText(value)

        const filtered = data.filter((item: any) =>
            item.phoneNumber.replace(/-/g, '').toLowerCase().includes(value.toLowerCase())
        )

        setFilteredData(filtered)
    }

    const calculateTotal = (key: keyof DataType) => {
        if (!filteredData || filteredData.length === 0) return 0
        return filteredData.reduce((sum: any, record) => sum + record[key], 0)
    }

    const formatNumber = (value: number) => {
        if (value >= 1000000) {
            return (value / 1000000).toFixed(3) + " ล้าน"
        } else if (value >= 100000) {
            return (value / 100000).toFixed(3) + " แสน"
        } else {
            return format(value)
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://667e0252297972455f66deaa.mockapi.io/api/v1/report')
                setData(response.data)
                setFilteredData(data)
            } catch (error) {
                console.error(error)
            } finally {
                setLoading(false)
            }
        }

        fetchData()
    }, [])

    useEffect(() => {
        if (data) {
            setFilteredData(data)
        }
    }, [data])

    useEffect(() => {
        const handleResize = () => {
            setIsLargeScreen(window.innerWidth > 768)
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return (
        <>
            <RangePicker
                format={dateFormat}
                size="small"
                popupClassName="partner-calendar"
                value={[buddhistYear(dayjs()), buddhistYear(dayjs().add(7, 'day'))]}
                style={{ width: '100%', marginBottom: 16, padding: '6.5px 12px' }}
                onChange={(dates) => {
                    if (dates) {
                        const [start, end] = dates
                        console.log("Selected Dates:", gregorianYear(start).format('YYYY-MM-DD'), gregorianYear(end).format('YYYY-MM-DD'))
                    }
                }}
            />
            <Search
                size="small"
                className="partner-search"
                addonBefore={<SearchOutlined />}
                allowClear
                placeholder="ค้นหาผู้เล่น"
                onSearch={(e: any) => handleSearch(e)}
                defaultValue={searchText}
                enterButton={"ค้นหา"}
            />
            <div className="partner-table-card">
                <Table
                    size="middle"
                    columns={columns}
                    dataSource={filteredData}
                    loading={loading}
                    scroll={{ x: true }}
                    sticky={{ offsetHeader: isLargeScreen ? 88 : 56 }}
                    rowClassName={(record, index) => (index % 2 === 0 ? 'even-row' : 'odd-row')}
                    summary={() => (
                        <Table.Summary fixed="bottom">
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0}>
                                    รวม
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={1}>
                                    {formatNumber(calculateTotal('deposit'))}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={2}>
                                    {formatNumber(calculateTotal('withdraw'))}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={3}>
                                    <div style={{ color: calculateTotal('deposit') - calculateTotal('withdraw') > 0 ? '#17c5a2' : 'red' }}>
                                        {formatNumber(calculateTotal('deposit') - calculateTotal('withdraw'))}
                                    </div>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={4}>
                                    <div style={{ color: calculateTotal('deposit') - calculateTotal('withdraw') > 0 ? '#17c5a2' : 'red' }}>
                                        {formatNumber((calculateTotal('deposit') - calculateTotal('withdraw')) * 0.2)}
                                    </div>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={5}>
                                    <div style={{ color: calculateTotal('deposit') - calculateTotal('withdraw') > 0 ? '#17c5a2' : 'red' }}>
                                        {formatNumber((calculateTotal('deposit') - calculateTotal('withdraw')) * 0.8)}
                                    </div>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={6}>
                                    <div style={{ color: calculateTotal('deposit') - calculateTotal('withdraw') > 0 ? '#17c5a2' : 'red' }}>
                                        {formatNumber(((calculateTotal('deposit') - calculateTotal('withdraw')) * 0.8) * 0.4)}
                                    </div>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </Table.Summary>
                    )}
                />
            </div>
        </>
    )
}

export default PartnerReport