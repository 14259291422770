import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Route, Router, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import App from './App'
import PrefixContainer from './components/Container/PrefixContainer'
import AuthLayout from './components/Layout/AuthLayout'
import history from './helpers/history'
import store from './store'
import Home from './views/Home'
import Login from './views/Login'
import LineLogin from './views/LineLogin'
import Register from './views/Register'
import './assets/scss/main.scss'
import 'react-toastify/dist/ReactToastify.css'
import ConfigProvider from './components/Container/ConfigProvider'
import { AppProvider } from './contexts/AppContext'
import Seller from './views/Seller'
import MoveWeb from './views/MoveWeb'
import RegisterNew from './views/RegisterNew'

declare global {
    interface Window {
        Winwheel: any
    }
}

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <AppProvider>
                <PrefixContainer>
                    <ConfigProvider>
                        <Router history={history}>
                            <Switch>
                                <Route path="/home" component={Home} />
                                <Route path="/login" component={AuthLayout(Login)} />
                                <Route path="/login-line" component={AuthLayout(LineLogin)} />
                                <Route path="/register" component={AuthLayout(Register)} />
                                <Route path="/register-new" component={AuthLayout(RegisterNew)} />
                                <Route path="/seller" component={Seller} />
                                <Route path="/move-web" component={MoveWeb} />
                                <App />
                            </Switch>
                        </Router>
                    </ConfigProvider>
                </PrefixContainer>
                <ToastContainer />
            </AppProvider>
        </Provider>
    </React.StrictMode >,
    document.getElementById('root')
)
