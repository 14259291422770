import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, matchPath } from 'react-router'
import { useHistory } from 'react-router-dom';
import { ApplicationState } from '../../store'
import BottomBar from './BottomBar'
import Sidebar from './Sidebar'
import Topbar from './Topbar'
import TopbarMobile from './TopbarMobile'
import { AskmebetConfig } from '../../entities/askmebet'
import api from '../../helpers/api'
import SetTheme from '../../utils/SetTheme'
import { toast } from 'react-toastify'
import MainAudio from '../Layout/MainAudio'
import ButtonFooter from './ButtonFooter'
import RedeemFree from '../Elements/RedeemFree'
import RedeemSuccess from './../Deposit/RedeemSuccess'
import RedeemDialog from '../Deposit/RedeemDialog';
import NotifyWebSocket from '../../utils/NotifyWebSocket'

import { useAppContext } from '../../contexts/AppContext';
import BottomBarRadius from './BottomBarRadius';

const AppLayout: FC = ({ children }) => {
	const elGame = useRef<HTMLDivElement>(null)
	const prefixState = useSelector((state: ApplicationState) => state.prefix)
	const userState = useSelector((state: ApplicationState) => state.user)
	const loc = useLocation()

	const matchSlotPath: any = matchPath(loc.pathname, {
		path: '/slot/:providerID',
		exact: true,
		strict: true
	})

	// sound
	const [isSound, setIsSound] = useState<boolean>(false)
	const setIsToggleSound = () => {
		const getLocalSound = localStorage.getItem('sound')
		if (getLocalSound === 'On') {
			setIsSound(true)
		} else {
			setIsSound(false)
		}
	}

	// Get provider name
	const [config, setConfig] = useState<AskmebetConfig | undefined>(undefined) // eslint-disable-line
	const initAskmebetConfig = useCallback(async () => {
		try {
			const res = await api.get<void, AskmebetConfig>('/askmebet')

			setConfig(res)
		} catch ({ message }: any) {
			toast.error(message as string)
		}
	}, [])

	// Context Redeem Free
	const {
		isOpenRedeemSuccess,
		setIsOpenRedeemSuccess,
		redeemAmount,
		isLoadedImagesRedeemSuccess,
		isOpenRedeemDialog,
		setIsOpenRedeemDialog,
		setIsModalSocket,
		extraRedeemSuccess,
		setExtraRedeemSuccess
	} = useAppContext();

	useEffect(() => {
		if (matchSlotPath?.isExact) {
			initAskmebetConfig()
		}
	}, [initAskmebetConfig]) // eslint-disable-line

	const [showMenuSupport, setShowMenuSupport] = useState<boolean>(true)

	useEffect(() => {
		if (prefixState.info) {
			const isNullishSocialMedia = prefixState.info?.socialMedia ? Object.values(prefixState.info?.socialMedia).every(value => {
				if (value === "") {
					return true;
				}
				return false;
			}) : true;
			setShowMenuSupport(isNullishSocialMedia)
		}
	}, [prefixState])

	// โหลดรูปภาพสำหรับ Popup ผลการรับโบนัสฟรี
	useEffect(() => {
		if (!isLoadedImagesRedeemSuccess) {
			setIsOpenRedeemSuccess(true);

			const timer = setTimeout(() => {
				setIsOpenRedeemSuccess(false);
			}, 300);

			return () => {
				clearTimeout(timer);
				setIsOpenRedeemSuccess(false);
			};
		}
	}, []);

	return (
		<>
			{loc.pathname !== '/play' ? (
				<>
					<div className="wrapper">
						<MainAudio prefixState={prefixState.info} isSound={isSound} setIsToggleSound={() => { setIsToggleSound() }} elGame={elGame} />
						<SetTheme />
						<Sidebar
							prefix={prefixState.info}
							member={userState.info}
							showMenuSupport={showMenuSupport}
							isSound={isSound}
							setIsToggleSound={() => { setIsToggleSound() }}
						/>
						<div className="content" ref={elGame}>
							<div className="layout-header">
								<Topbar
									prefix={prefixState.info}
									member={userState.info}
									pathname={loc.pathname}
									setIsToggleSound={() => { setIsToggleSound() }}
								/>

								{(loc.pathname !== '/wallet' && loc.pathname !== '/launch-game') && (
									<TopbarMobile pathname={loc.pathname} prefix={prefixState.info} member={userState.info} />
								)}
							</div>
							<div className="web-container">
								{children}
							</div>
							<ButtonFooter prefixState={prefixState} setIsToggleSound={setIsToggleSound} />
							{ prefixState.info?.themeBottombar === "bottom_radius" ?
								<BottomBarRadius member={userState.info} prefix={prefixState.info}/> :
								<BottomBar member={userState.info} prefix={prefixState.info}/>
							}
						</div>
					</div>
					{prefixState.info?.bonusFreeStatus && <RedeemFree />}

					<RedeemDialog isOpen={isOpenRedeemDialog} onClose={() => setIsOpenRedeemDialog(false)} />
					<RedeemSuccess amount={redeemAmount} isOpen={isOpenRedeemSuccess} isLoadedImages={isLoadedImagesRedeemSuccess} onClose={() => {setIsOpenRedeemSuccess(false); setExtraRedeemSuccess(""); }} extraText={extraRedeemSuccess}/>
				</>
			) : (
				<>
					<SetTheme />
					{children}
				</>
			)}
			{/* {(prefixState.info?.prefix === 'PGAUTO68' || prefixState.info?.prefix === 'MUMMY') && */}
			<NotifyWebSocket setIsModalSocket={setIsModalSocket} />
			{/* } */}
		</>

	)
}

export default AppLayout
