import React from "react"
import { Col, DatePicker, Row, Tag } from "antd"
import dayjs from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"
import { format, formatCurrency } from '../../helpers/number';

dayjs.extend(customParseFormat)

const { RangePicker } = DatePicker

const PartnerSummary = () => {
    const dateFormat = "DD/MM/YYYY"

    const buddhistYear = (date: any) => {
        if (!date) return date
        return date.year(date.year() + 543)
    }

    const gregorianYear = (date: any) => {
        if (!date) return date
        return date.year(date.year() - 543)
    }

    const splitNumber = (numberString: string): [string, string] => {
        const [integerPart, decimalPart] = numberString.split('.')
        return [integerPart + '.', decimalPart || '00']
    }

    return (
        <>
            <RangePicker
                format={dateFormat}
                size="small"
                popupClassName="partner-calendar"
                value={[buddhistYear(dayjs()), buddhistYear(dayjs().add(7, 'day'))]}
                style={{ width: '100%', marginBottom: 16, padding: '6.5px 12px' }}
                onChange={(dates) => {
                    if (dates) {
                        const [start, end] = dates;
                        console.log("Selected Dates:", gregorianYear(start).format('YYYY-MM-DD'), gregorianYear(end).format('YYYY-MM-DD'));
                    }
                }}
            />
            <Row gutter={[12, 12]}>
                <Col xs={12} sm={12}>
                    <div className="partner-statistic">
                        <div className="partner-statistic-title">ยอดฝาก</div>
                        <div className="partner-statistic-amount deposit">
                            <span style={{ fontSize: 24, fontWeight: 'normal' }}>฿</span> <span>{splitNumber(format(123546.123))[0]}</span><span style={{ fontSize: 24 }}>{splitNumber(format(123546.123))[1]}</span>
                        </div>
                        <div className="partner-statistic-extra">1,235 รายการ</div>
                    </div>
                </Col>
                <Col xs={12} sm={12}>
                    <div className="partner-statistic">
                        <div className="partner-statistic-title">ยอดถอน</div>
                        <div className="partner-statistic-amount withdraw">
                        <span style={{ fontSize: 24, fontWeight: 'normal' }}>฿</span> <span>{splitNumber(format(123546.123))[0]}</span><span style={{ fontSize: 24 }}>{splitNumber(format(123546.123))[1]}</span>
                        </div>
                        <div className="partner-statistic-extra">1,235 รายการ</div>
                    </div>
                </Col>
                <Col xs={12} sm={12}>
                    <div className="partner-statistic">
                        <div className="partner-statistic-title">กำไร</div>
                        <div className="partner-statistic-amount profit">
                        <span style={{ fontSize: 24, fontWeight: 'normal' }}>฿</span> <span>{splitNumber(format(123546.123))[0]}</span><span style={{ fontSize: 24 }}>{splitNumber(format(123546.123))[1]}</span>
                        </div>
                    </div>
                </Col>
                <Col xs={12} sm={12}>
                    <div className="partner-statistic">
                        <div className="partner-statistic-title">ยอดบริษัท <Tag className="partner-tag" style={{ lineHeight: 1, padding: '0 5px', marginLeft: 4 }}>20%</Tag></div>
                        <div className="partner-statistic-amount company">
                        <span style={{ fontSize: 24, fontWeight: 'normal' }}>฿</span> <span>{splitNumber(format(123546.123))[0]}</span><span style={{ fontSize: 24 }}>{splitNumber(format(123546.123))[1]}</span>
                        </div>
                    </div>
                </Col>
                <Col xs={12} sm={12}>
                    <div className="partner-statistic">
                        <div className="partner-statistic-title">กำไรสุทธิ</div>
                        <div className="partner-statistic-amount net-profit">
                        <span style={{ fontSize: 24, fontWeight: 'normal' }}>฿</span> <span>{splitNumber(format(123546.123))[0]}</span><span style={{ fontSize: 24 }}>{splitNumber(format(123546.123))[1]}</span>
                        </div>
                    </div>
                </Col>
                <Col xs={12} sm={12}>
                    <div className="partner-statistic">
                        <div className="partner-statistic-title">คอมมิชชั่น <Tag className="partner-tag" style={{ lineHeight: 1, padding: '0 5px', marginLeft: 4 }}>40%</Tag></div>
                        <div className="partner-statistic-amount commission">
                        <span style={{ fontSize: 24, fontWeight: 'normal' }}>฿</span> <span>{splitNumber(format(123546.123))[0]}</span><span style={{ fontSize: 24 }}>{splitNumber(format(123546.123))[1]}</span>
                        </div>
                    </div>
                </Col>
                <Col xs={12} sm={12}>
                    <div className="partner-statistic">
                        <div className="partner-statistic-title">สมาชิกใหม่</div>
                        <div className="partner-statistic-amount new-member">{format(12345, 0)} <span style={{ fontSize: 24 }}>คน</span></div>
                    </div>
                </Col>
                <Col xs={12} sm={12}>
                    <div className="partner-statistic">
                        <div className="partner-statistic-title">สมาชิกที่ฝาก</div>
                        <div className="partner-statistic-amount deposit-member">{format(56788, 0)} <span style={{ fontSize: 24 }}>คน</span></div>
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default PartnerSummary;