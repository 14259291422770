import React, { FC, useEffect, useState } from 'react'
import { Avatar, Divider, Tag } from 'antd'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { getDomainWithoutSubdomain } from '../../utils/domainWithoutSubdomain'
import CarouselItem from '../Elements/CarouselItem'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../store'
interface Props {
    prefixState: any
}

const Footer: FC<Props> = ({ prefixState }) => {
    const [tags, setTags] = useState<string[]>([])
    const [copyright, setCopyright] = useState<string>()
    const YearNow = dayjs().format('YYYY')
    const [providerSlot, setProviderSlot] = useState<any>(undefined)
    const [loadingAMBKing, setLoadingAMBKing] = useState<boolean>(false)
    const providerHomeState = useSelector((state: ApplicationState) => state.gamesHome)

    // const Image1 = [
    //     {
    //         image: "/bank-logo/truewallet.svg",
    //         title: "truewallet"
    //     },
    //     {
    //         image: "/bank-logo/scb.svg",
    //         title: "scb"
    //     },
    //     {
    //         image: "/bank-logo/kbank.svg",
    //         title: "kbank"
    //     },
    //     {
    //         image: "/bank-logo/ktb.svg",
    //         title: "ktb"
    //     },
    //     {
    //         image: "/bank-logo/ttb.svg",
    //         title: "ttb"
    //     },
    //     {
    //         image: "/bank-logo/bay.svg",
    //         title: "bay"
    //     },
    //     {
    //         image: "/bank-logo/uobt.svg",
    //         title: "uobt"
    //     },
    //     {
    //         image: "/bank-logo/baac.svg",
    //         title: "baac"
    //     },
    //     {
    //         image: "/bank-logo/bbl.svg",
    //         title: "bbl"
    //     },
    //     {
    //         image: "/bank-logo/kk.svg",
    //         title: "kk"
    //     },
    //     {
    //         image: "/bank-logo/lh.svg",
    //         title: "lh"
    //     },
    //     {
    //         image: "/bank-logo/cimb.svg",
    //         title: "cimb"
    //     },
    // ]
    // const Image2 = [
    //     {
    //         image: "/bank-logo/gsb.svg",
    //         title: "gsb"
    //     },
    //     {
    //         image: "/bank-logo/gh.svg",
    //         title: "gh"
    //     },
    //     {
    //         image: "/bank-logo/tisco.svg",
    //         title: "tisco"
    //     },
    //     {
    //         image: "/bank-logo/boc.svg",
    //         title: "boc"
    //     },
    //     {
    //         image: "/bank-logo/bofa.svg",
    //         title: "bofa"
    //     },
    //     {
    //         image: "/bank-logo/cacib.svg",
    //         title: "cacib"
    //     },
    //     {
    //         image: "/bank-logo/citi.svg",
    //         title: "citi"
    //     },
    //     {
    //         image: "/bank-logo/hsbc.svg",
    //         title: "hsbc"
    //     },
    //     {
    //         image: "/bank-logo/ibank.svg",
    //         title: "ibank"
    //     },
    //     {
    //         image: "/bank-logo/icbc.svg",
    //         title: "icbc"
    //     },
    //     {
    //         image: "/bank-logo/sc.svg",
    //         title: "sc"
    //     },
    //     {
    //         image: "/bank-logo/tbank.svg",
    //         title: "tbank"
    //     },
    // ]


    useEffect(() => {
        if (prefixState.info?.game !== "pgoon" && prefixState.info?.game !== "betflix") {
            if (providerHomeState?.info !== undefined) {
                setProviderSlot(providerHomeState?.info?.games['slot'])
                setLoadingAMBKing(true)
            }
        }
    }, [providerHomeState?.info]) // eslint-disable-line

    useEffect(() => {
        setTags(prefixState.info?.seo.tags || [])
        setCopyright(`Copyright © ${YearNow} All Right Reserved.`)
        if (window.location.hostname !== 'localhost') {
            const nameUrl = getDomainWithoutSubdomain(window.location.host).split('.')[0]
            const nameWebsite = nameUrl.charAt(0).toUpperCase() + nameUrl.slice(1);
            setCopyright(`Copyright © ${YearNow} ${nameWebsite} All Right Reserved.`)
        }
    }, [prefixState.info?.seo.tags])//eslint-disable-line

    return (
        <div className="footer">
            <div className="footer-info">
                {prefixState.info?.logo && (
                    <Link to="/wallet">
                        <Avatar
                            className="avatar-logo avatar-logo-sidebar"
                            src={prefixState.info?.logo}
                            shape="square"
                            size={80}
                            style={{ marginBottom: 16 }} />
                    </Link>
                )}

                {prefixState.info?.seo?.title ?
                    <h1>{prefixState.info?.seo?.title}</h1> :
                    <h1>{prefixState.info?.title}</h1>
                }

                {prefixState.info?.seo?.description ?
                    <p>{prefixState.info?.seo?.description}</p> :
                    <p>{prefixState.info?.title}</p>
                }
            </div>
            <div className="footer-tags">
                {tags.map((tag, index) => (
                    <a href={`https://${window.location.hostname}`} rel="dofollow" key={index}>
                        <Tag style={{ maxWidth: '100%', whiteSpace: 'break-spaces' }}>{tag}</Tag>
                    </a>
                ))}
            </div>
            {(prefixState.info?.game === 'pgslot' || prefixState.info?.game === 'betflix') && (
                <div className="footer-partners">
                    <img src="/provider-icon/PG-Soft.webp" alt="PG" />
                    <img src="/provider-icon/CQ9.webp" alt="CQ9" />
                    <img src="/provider-icon/AMBS.webp" alt="AMBS" />
                    <img src="/provider-icon/EVO.webp" alt="EVO" />
                    <img src="/provider-icon/Jili.webp" alt="Jili" />
                    <img src="/provider-icon/NS.webp" alt="NS" />
                    <img src="/provider-icon/PNG.webp" alt="PNG" />
                    <img src="/provider-icon/SPN.webp" alt="SPN" />
                    <img src="/provider-icon/wm.png" alt="wm" />
                    <img src="/provider-icon/sa.png" alt="sa" />
                    <img src="/provider-icon/allbet.png" alt="allbet" />
                    <img src="/provider-icon/sexy.png" alt="sexy" />
                    <img src="/provider-icon/ae.png" alt="ae" />
                    <img src="/provider-icon/dream.png" alt="dream" />
                    <img src="/provider-icon/mglive.png" alt="mglive" />
                    <img src="/provider-icon/pretty.png" alt="pretty" />
                    <img src="/provider-icon/n2live.png" alt="n2live" />
                    <img src="/provider-icon/bg.png" alt="bg" />
                    <img src="/provider-icon/entwine.png" alt="entwine" />
                    <img src="/provider-icon/evolution.png" alt="evolution" />
                    <img src="/provider-icon/xg.png" alt="xg" />
                    <img src="/provider-icon/playtechlive.png" alt="playtechlive" />
                    <img src="/provider-icon/pragmatic.png" alt="pragmatic" />
                    <img src="/provider-icon/ebet.png" alt="ebet" />
                </div>
            )}
            {providerSlot !== undefined && loadingAMBKing && prefixState.info?.game === 'ambking' && (
                <div className="footer-partners">
                    {providerSlot.map((item: any, index: number) => (
                        item.provider !== "POPG" &&
                        item.provider !== "RECG" &&
                        item.provider !== "BIGW" &&
                        item.provider !== "NEWG" &&
                        <img src={item.logoURL} alt={item.provider} key={index} style={{ borderRadius: '10px' }} />
                    ))}
                </div>
            )}
            {/* {((prefixState.info.prefix).toUpperCase() === "MUMMY" || (prefixState.info.prefix).toUpperCase() === "SHABU") && (
                <>
                    <Divider />
                    <div className="footer-partners bank">
                        <img src="/bank-logo/truewallet.svg" alt="truewallet" />
                        <img src="/bank-logo/scb.svg" alt="scb" />
                        <img src="/bank-logo/kbank.svg" alt="kbank" />
                        <img src="/bank-logo/ktb.svg" alt="ktb" />
                        <img src="/bank-logo/ttb.svg" alt="ttb" />
                        <img src="/bank-logo/bay.svg" alt="bay" />
                        <img src="/bank-logo/uobt.svg" alt="uobt" />
                        <img src="/bank-logo/baac.svg" alt="baac" />
                        <img src="/bank-logo/bbl.svg" alt="bbl" />
                        <img src="/bank-logo/kk.svg" alt="kk" />
                        <img src="/bank-logo/lh.svg" alt="lh" />
                        <img src="/bank-logo/cimb.svg" alt="cimb" />
                        <img src="/bank-logo/gsb.svg" alt="gsb" />
                        <img src="/bank-logo/gh.svg" alt="gh" />
                        <img src="/bank-logo/tisco.svg" alt="tisco" />
                        <img src="/bank-logo/boc.svg" alt="boc" />
                        <img src="/bank-logo/bofa.svg" alt="bofa" />
                        <img src="/bank-logo/cacib.svg" alt="cacib" />
                        <img src="/bank-logo/citi.svg" alt="citi" />
                        <img src="/bank-logo/hsbc.svg" alt="hsbc" />
                        <img src="/bank-logo/ibank.svg" alt="ibank" />
                        <img src="/bank-logo/icbc.svg" alt="icbc" />
                        <img src="/bank-logo/sc.svg" alt="sc" />
                        <img src="/bank-logo/tbank.svg" alt="tbank" />
                    </div>
                </>
            )} */}

            {/* <div className="carousel-container">
                        <div className="carousel-track">
                            {Image1.map((item, index) => (
                                <CarouselItem
                                    imgUrl={item.image}
                                    imgTitle={item.title}
                                    key={index}
                                ></CarouselItem>
                            ))}
                            {Image2.slice().reverse().map((item, index) => (
                                <CarouselItem
                                    imgUrl={item.image}
                                    imgTitle={item.title}
                                    key={index}
                                ></CarouselItem>
                            ))}
                        </div>
                    </div>
                    <div className="carousel-container2">
                        <div className="carousel-track2">
                            {Image2.map((item, index) => (
                                <CarouselItem
                                    imgUrl={item.image}
                                    imgTitle={item.title}
                                    key={index}
                                ></CarouselItem>
                            ))}
                        </div>
                    </div> */}
            <div className="footer-info" style={{ marginTop: '20px' }}>
                <p>{copyright}</p>
            </div>
        </div>
    )
}

export default Footer
