import React, { useState, useMemo } from "react"
import { Button, Col, Divider, message, Input, Radio, RadioChangeEvent, Row, Tag, Typography } from "antd"
import { CopyOutlined } from '@ant-design/icons'
import { useHistory, useLocation } from 'react-router-dom'
import PartnerSummary from '../components/Partner/PartnerSummary'
import PartnerReport from '../components/Partner/PartnerReport'
import PartnerHistory from '../components/Partner/PartnerHistory'

const { Text } = Typography

const Partner = () => {
    const [tab, setTab] = useState('summary')
    const history = useHistory()
    const location = useLocation()
    const params = new URLSearchParams(location.search);
    const [messageApi, contextHolder] = message.useMessage()

    const copyAffiliateLink = () => {
        navigator.clipboard.writeText("https://www.test.com/register?ref=123456")

        messageApi.open({
            type: 'success',
            content: 'คัดลอกลิงก์ชวนเพื่อนสำเร็จ',
        })
    }

    const onChange = (e: RadioChangeEvent) => {
        setTab(e.target.value)
        const params = new URLSearchParams(location.search);
        params.set('tab', e.target.value);
        history.push({ search: params.toString() });
    }

    useMemo(() => {
        const paramsTab: any = params.get('tab') ? params.get('tab') : 'summary';
        if (paramsTab) {
            setTab(paramsTab)
        }
    }, []) //eslint-disable-line

    return (
        <div>
            <Row justify="center">
                <Col xs={24} md={20}>
                    <div className="partner-card primary-bg">
                        <div className="partner-commission">
                            <div className="partner-commission-amount">
                                <Text type="secondary">
                                    <span>คอมมิชชั่น</span>
                                    <Tag className="partner-tag" style={{ marginLeft: 10, lineHeight: 1.2 }}>40%</Tag>
                                </Text>
                                <div className="partner-commission-number">฿ 1,234.00</div>
                            </div>
                            <Button className="ant-btn-secondary">ถอนเงิน</Button>
                        </div>
                        <Divider style={{ borderBlockStart: '1px solid rgba(255, 255, 255, 0.1)' }} />
                        <div className="partner-link">
                            <Text type="secondary" style={{ display: 'block', marginBottom: 4 }}>ลิงก์สมัคร</Text>
                            <Input.Group
                                style={{ display: 'flex' }}
                                size="small"
                                compact
                            >
                                <Input
                                    value={'https://www.test.com/register?ref=123456'}
                                    style={{ background: '#0d0f10', color: '#fff' }}
                                    disabled
                                />
                                <Button
                                    type='primary'
                                    icon={<CopyOutlined style={{ fontSize: 18, marginTop: 1 }} />}
                                    style={{ height: 'auto' }}
                                    onClick={copyAffiliateLink}
                                ></Button>
                            </Input.Group>
                        </div>
                    </div>
                    <Radio.Group
                        buttonStyle="solid"
                        className="transaction-tab"
                        value={tab}
                        defaultValue={tab}
                        onChange={onChange}
                        style={{ marginBottom: 16 }}
                    >
                        <Radio.Button value="summary">สรุปยอด</Radio.Button>
                        <Radio.Button value="report">รายงานผู้เล่น</Radio.Button>
                        <Radio.Button value="history">ประวัติถอน</Radio.Button>
                    </Radio.Group>

                    <div>
                        {tab === "summary" && (
                            <PartnerSummary />
                        )}
                        {tab === "report" && (
                            <PartnerReport />
                        )}
                        {tab === "history" && (
                            <PartnerHistory />
                        )}
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Partner;