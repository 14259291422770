import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import HomeLayout from '../components/Layout/HomeLayout'
import MainSlider from '../components/Wallet/MainSlider'
import WalletMobileMenu from '../components/Home/WalletMobileMenu'
import WalletPromotionContainer from '../components/Home/WalletPromotionContainer'
import WalletPromotionContainer2 from '../components/Home/WalletPromotionContainer2'
import { ApplicationState } from '../store'
import Alert from '../components/Home/Alert'
import ModalPromotions from '../components/Home/ModalPromotions'
import ModalSupport from '../components/Home/ModalSupport'
import AMBKing from '../components/LaunchGame/AMBKing1'
import BetflixHome from '../components/LaunchGame/BetflixHome'
import PGOON from '../components/LaunchGame/PGOONGame'
import Footer from '../components/Layout/Footer'
// import ButtonFooter from '../components/Layout/ButtonFooter'
import { useHistory, useLocation } from 'react-router-dom'
import { makePath } from '../helpers/path'
import SeoContent from '../components/Layout/SeoContent'
import { Prefix } from '../@types/prefix';

const Home = () => {
    const prefixState = useSelector((state: ApplicationState) => state.prefix)
    const history = useHistory()

    const [showMenuSupport, setShowMenuSupport] = useState<boolean>(true)

    const [openAlert, setOpenAlert] = useState<boolean>(false)
    const handleAlert = () => setOpenAlert(!openAlert)

    const [openPromotion, setOpenPromotion] = useState<boolean>(false)
    const handleModalPromotionst = () => setOpenPromotion(!openPromotion)

    const [openSupport, setOpenSupport] = useState<boolean>(false)
    const handleModalSupport = () => setOpenSupport(!openSupport)

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const getParamsRec = params.get('rec');
    const getParamsRef = params.get('ref');

    if (getParamsRec) {
        localStorage.setItem('rec', getParamsRec)
    }

    if (getParamsRef) {
        localStorage.setItem('ref', getParamsRef)
    }

    useEffect(() => {
        if (prefixState.info) {
            const isNullishSocialMedia = prefixState.info?.socialMedia ? Object.values(prefixState.info?.socialMedia).every(value => {
                if (value === "") {
                    return true;
                }
                return false;
            }) : true;
            setShowMenuSupport(isNullishSocialMedia)

            if (!prefixState?.info?.functions.hasOwnProperty("homePage")) {
                history.push(makePath('/wallet'))
            } else {
                if (!prefixState?.info?.functions['homePage']) {
                    history.push(makePath('/wallet'))
                }
            }
        }
    }, [prefixState]) // eslint-disable-line

    return (
        <HomeLayout>
            {(prefixState.info?.game)?.toUpperCase() === "AMBKING" ? (
                <AMBKing />
            ) : (prefixState.info?.game)?.toUpperCase() === "BETFLIX" && prefixState.info?.functions.betflixListgameOnHomePage ? (
                <BetflixHome />
            ) : (prefixState.info?.game)?.toUpperCase() === "PGOON" && prefixState.info?.functions.pgoonListgameOnHomePage ? (
                <PGOON />
            ) : (
                <>
                    <MainSlider prefixState={prefixState} />
                    {/* Mobile menu */}
                    {((prefixState.info?.game)?.toUpperCase() === "BETFLIX" || (prefixState.info?.game)?.toUpperCase() === "PGSLOT") &&
                        prefixState.info?.functions.homePromotionOnlyPage ? (
                        <WalletPromotionContainer2 promotions={prefixState.info?.promotions} />
                    ) : (
                        <>
                            {prefixState.info?.prefix !== "PGOK" && (
                                <WalletMobileMenu
                                    lineLink={prefixState.info?.lineLink || ''}
                                    checkin={prefixState.info?.checkin.enable || false}
                                    wheel={prefixState.info?.wheel.enable || false}
                                    commission={prefixState.info?.commission || false}
                                    showMenuSupport={showMenuSupport}
                                    ranking={prefixState.info?.functions.rank || false}
                                    alert={handleAlert}
                                    modalPromotions={handleModalPromotionst}
                                    modalSupport={handleModalSupport}
                                    bonusFreeStatus={prefixState.info?.bonusFreeStatus || false}
                                />
                            )}
                            {/* Promotion */}
                            {(prefixState.info?.promotions.length || 0) > 0 && !prefixState.info?.functions.homePromotionPage && (
                                <WalletPromotionContainer promotions={prefixState.info?.promotions} prefix={prefixState.info?.prefix} />
                            )}
                        </>
                    )}

                    <SeoContent />
                    <Footer prefixState={prefixState} />
                </>
            )}

            <Alert
                isOpen={openAlert}
                onClose={() => setOpenAlert(false)} />

            <ModalPromotions
                isOpen={openPromotion}
                onClose={() => setOpenPromotion(false)} />

            <ModalSupport
                isOpen={openSupport}
                onClose={() => setOpenSupport(false)} />
        </HomeLayout>
    )
}

export default Home
