import React from "react"
import { Flex, Typography } from "antd"
import dayjs from "dayjs"
import { format, formatCurrency } from '../../helpers/number';
import StatusBadge from '../Transaction/StatusBadge'
import { getBankName } from '../../helpers/bank'

const { Text } = Typography

const PartnerHistory = () => {
    return (
        <>
            <div className="transaction-card" key={1}>
                <div className="transaction-header">
                    <div className="transaction-info">
                        <div className="transaction-title">ถอนเงิน</div>
                    </div>
                    <div className="transaction-title">฿ {format(12344)}</div>
                </div>
                <div className="transaction-body-remark">
                    <Flex gap={'small'} justify='space-between' align='center'>
                        <Flex gap={'small'} align='center'>
                            <Text>ไปยัง</Text>
                            <img
                                src={`/bank-logo/scb.svg`}
                                alt={"scb"}
                                height={24}
                                width={24}
                            />
                            <Text>{getBankName("SCB")}</Text>
                        </Flex>
                        {/* {tx.transferTo === 'PEER2PAY' && tx.status === 'success' && (
                            <Button
                                type="primary"
                                onClick={() => window.open(`https://peer2pay.com/?action=requestSell&amt=${tx.amount}&t=THBP&c=THB`, '_blank')}
                                style={{ minWidth: 120, fontSize: 22 }}
                                icon={<i className="fa-solid fa-money-simple-from-bracket" style={{ fontSize: 16 }}></i>}
                                size='small'
                                disabled={tx.status === 'success' ? false : true}>
                                <span>ถอนเงินสด</span>
                            </Button>
                        )} */}
                    </Flex>
                </div>
                <div className="transaction-body">
                    <div className="tag-default">{dayjs("01/01/2024 23:59").format('DD/MM/YYYY HH:mm')}</div>
                    <StatusBadge status={"success"} />
                </div>
            </div>
        </>
    );
}

export default PartnerHistory;