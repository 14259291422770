export const topbarText: { [key: string]: string } = {
	'/deposit': 'เติมเงิน',
	'/deposit/bank': 'โอนผ่านธนาคาร',
	'/deposit/wallet': 'ทรูมันนี่วอลเล็ท',
	'/deposit/ambpay': 'โอนผ่านระบบ QR',
	'/deposit/upload-slip': 'อัพโหลดสลิป',
	'/withdraw': 'ถอนเงิน',
	'/transactions': 'ประวัติ',
	'/promotion': 'โปรโมชั่น',
	'/lucky-wheel': 'กงล้อ',
	'/affiliate': 'ชวนเพื่อน',
	'/commission': 'คอมมิชชั่น',
	'/profile': 'โปรไฟล์',
	'/account': 'บัญชี',
	'/support': 'ติดต่อเรา',
	'/ranking': 'จัดอันดับ',
	'/check-in': 'เช็คอิน',
	'/partner': 'พันธมิตร',
	'/launch-game': 'เข้าเล่นเกม',
}

export const txTypeText: { [key: string]: string } = {
	deposit: 'เติมเงิน',
	withdraw: 'ถอนเงิน',
	promotion: 'รับโบนัส',
	cashback: 'รับแคชแบ็ก',
	wheel: 'กงล้อเสี่ยงโชค',
	add: 'เติมเครดิต',
	deduct: 'ตัดเครดิต',
	redeem: 'โค้ดคูปอง',
	checkin: 'เช็คอิน',
	partner: 'พันธมิตร',
	affiliate_transfer: 'โยกยอดเชิญเพื่อน',
	'new-reg-bonus': 'เครดิตสมาชิกใหม่',
}
