import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getBankName, getBankColor, Banks } from '../helpers/bank'
import { ApplicationState } from '../store'
import { Button, Col, Divider, Row, Statistic, Tag, theme, Typography, message } from 'antd'
import { makePath } from '../helpers/path'
import { PhoneOutlined } from '@ant-design/icons';
import { format } from '../helpers/number'
import api from '../helpers/api'
import { urlLineLink } from '../@types/member'
import { toast } from 'react-toastify'
import ChangePassword from '../components/Profile/ChangePassword'

const { Text } = Typography
const { useToken } = theme

const Profile = () => {
	const { token } = useToken()
	const userState = useSelector((state: ApplicationState) => state.user)
	const prefixState = useSelector((state: ApplicationState) => state.prefix)
	const info = userState.info
	const prefix = prefixState.info
	const charAvatar = info?.firstName.substring(0, 1)
	const myRank: any = userState.info ? userState.info.rank : { name: "", point: 0 }

	const [changePassword, setChangePassword] = useState<boolean>(false)
	const handleChangePassword = () => setChangePassword(!changePassword)

	if (!info) {
		return <div>ไม่พบโปรไฟล์ของคุณในขณะนี้</div>
	}

	const [messageApi, contextHolder] = message.useMessage();
	const copyGameUsername = () => {
		navigator.clipboard.writeText(
			info.game?.meta?.applicationUsername || info.game?.username || 'username'
		)
		messageApi.open({
			type: 'success',
			content: 'คัดลอกชื่อผู้ใช้งานสำเร็จ',
		});
	}
	const copyGamePassword = () => {
		navigator.clipboard.writeText(info.game.password || 'password')
		messageApi.open({
			type: 'success',
			content: 'คัดลอกรหัสผ่านสำเร็จ',
		});
	}

	const getLinkLine = async () => {
		try {
			const res = await api.get<void, urlLineLink>('/generate-link-line')
			window.location.href = res.url;
		} catch ({ message }: any) {
			toast.error(message as string)
		}
	}

	const isAccount = (prefix?.game !== "ambbet" && prefix?.game !== "ambking" && prefix?.game !== "wingbet" && prefix?.game !== '2goalbet' && prefix?.game !== 'pgoon') &&
		(
			<>
				<Row gutter={[16, 16]} style={{ marginBottom: 24 }}>
					<Col xs={12} sm={12}>
						<Statistic title="Username" formatter={(value) => value} value={info.game?.meta?.applicationUsername || info.game?.username} />
						<Button
							className="ant-btn-dark"
							size="small"
							icon={<i className="fa-regular fa-copy" style={{ fontSize: 16 }}></i>}
							onClick={copyGameUsername}
							style={{ marginTop: 12 }}
						>
							คัดลอก
						</Button>
					</Col>
					<Col xs={12} sm={12}>
						<Statistic title="Password" formatter={(value) => value} value={info.game.password} />
						<Button
							className="ant-btn-dark"
							size="small"
							icon={<i className="fa-regular fa-copy" style={{ fontSize: 16 }}></i>}
							onClick={copyGamePassword}
							style={{ marginTop: 12 }}
						>
							คัดลอก
						</Button>
					</Col>
				</Row>
				<Divider />
			</>
		)

	return (
		<>
			{contextHolder}
			<Row justify="center" className="margin-form-card">
				<Col xs={24} md={16} xl={12}>
					<div className="form-card">
						<div className="form-card-body">
							<div className="account-menu" style={{ padding: 12, marginBottom: 16 }}>
								<div className="topbar-member">
									<div className="topbar-profile">
										{prefixState?.info?.functions?.rank ? (
											<Link to={makePath('/ranking')}>
												<div className="topbar-profile-rank">
													<img src={`/ranks/${myRank.name === '' ? 'bronze' : myRank.name}.svg`} alt={myRank.name === '' ? 'bronze' : myRank.name} />
												</div>
											</Link>
										) : (
											<div className="topbar-avatar">{charAvatar}</div>
										)}
										<div className="topbar-info">
											<Text className="topbar-name">
												<span>{info.firstName} {info.lastName}</span>
											</Text>
											<div>
												<Text type="secondary">฿ {format(userState.credit)}</Text>
											</div>
											<Tag
												icon={<PhoneOutlined style={{ width: 16, color: 'var(--color-text-primary-bg)' }} rev={undefined} />}
												color={token.colorPrimary}
												style={{ display: 'inline-flex', color: 'var(--color-text-primary-bg)' }}
											>
												{info.phone}
											</Tag>
										</div>
									</div>
								</div>
							</div>
							{prefix?.functions?.lineLogin &&
								<div className="bank-card" style={{ display: 'block' }}>
									<div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<img src="/icons/new/line-logo.svg" alt="line logo" style={{ width: 40 }} />
											<div style={{ paddingLeft: 20, lineHeight: 1 }}>
												<div><Text>เข้าสู่ระบบด้วย LINE</Text></div>
											</div>
										</div>
										<div style={{ display: 'flex', alignItems: 'center' }}>
											{userState?.info?.lineUserID ? (
												<Button
													className="ant-btn-success"
													size="small"
													style={{ fontSize: 22, padding: '0 12px' }}
													disabled
												>
													เชื่อมต่อแล้ว
												</Button>
											) : (
												<Button
													className="ant-btn-dark"
													size="small"
													onClick={() => { getLinkLine() }}
													style={{ fontSize: 22, padding: '0 12px' }}
												>เชื่อมต่อบัญชี</Button>
											)}
										</div>
									</div>
									{userState?.info?.lineUserID && <div className="uid-line"><Text type="secondary"><small>UID : {userState?.info?.lineUserID}</small></Text></div>}
								</div>
							}
							<Divider />
							{isAccount}
							<Text style={{ marginBottom: 6, display: 'block' }}>ข้อมูลบัญชีธนาคาร</Text>
							<div className="bank-card">
								<div
									className="bank-card-logo"
									style={{ boxShadow: `0 8px 12px -4px ${getBankColor(info.bankCode)}` }}
								>
									<img
										src={Banks.filter(bankName => bankName.key === info?.bankCode.toUpperCase())[0].image}
										alt={getBankName(info.bankCode)}
									/>
								</div>
								<div className="bank-card-info">
									<div><small>{getBankName(info.bankCode)}</small></div>
									<div className="bank-deposite-account">{info.bankNumber}</div>
									<div><small>{info.firstName} {info.lastName}</small></div>
								</div>
							</div>
							{info.peer2PayUID && (
								<div className="bank-card" style={{ marginTop: 16 }}>
									<div
										className="bank-card-logo"
										style={{ boxShadow: "0 8px 12px -4px #fff" }}
									>
										<img src={Banks.filter(bankName => bankName.key === "PEER2PAY")[0].image} alt={getBankName("PEER2PAY")} />
									</div>
									<div className="bank-card-info" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
										<div><Text>Peer2Pay</Text></div>
										<div className="bank-deposite-account">UID : {info.peer2PayUID}</div>
									</div>
								</div>
							)}
							<Button className="ant-btn-primary" style={{ marginTop: 16 }} block onClick={handleChangePassword}>เปลี่ยนรหัสผ่าน</Button>
							<Link to={makePath('/logout')}>
								<Button className="ant-btn-dark" style={{ marginTop: 16 }} block>ออกจากระบบ</Button>
							</Link>
						</div>
					</div>
				</Col>
			</Row>

			<ChangePassword isOpen={changePassword} onClose={() => setChangePassword(false)} />
		</>
	)
}

export default Profile
