// import { useFormik } from 'formik'
import qs from 'querystring'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import RegisterStepVerifyOTP from '../components/Register/RegisterStepVerifyOTP'
import api from '../helpers/api'
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { makePath } from '../helpers/path'
import AuthService from '../services/auth'
import { ApplicationState } from '../store'
import { setUser } from '../store/user/user.actions'
import ModalSupport from '../components/Home/ModalSupport';
import { Button, Card, Form, Typography, Tabs, Input, Select, Flex, Checkbox, Row, Col, Space, Modal } from 'antd';
import type { TabsProps } from 'antd';
import ModalTerms from '../components/Elements/ModalTerms'
import MainSlider from '../components/Wallet/MainSlider'
import { PatternFormat } from 'react-number-format'
import { bankNames } from '../helpers/bank'
import PatternNumber from '../helpers/PatternNumber'

interface RegisterResponse {
    token: string
}

interface VerifyResponse {
    ref: string
    transaction_id: string
    token: string
    status: boolean
}

const dataRegister = {
    phone: '',
    bankCode: '',
    bankNumber: '',
    firstName: '',
    lastName: '',
    firstNameEN: '',
    lastNameEN: '',
    password: '',
    line: '',
    autoBonus: '',
    knowFrom: '',
    affiliateCode: '',
    captcha: ''
}

const { Title, Text } = Typography;


const RegisterNew = () => {

    const [form] = Form.useForm();
    const dispatch = useDispatch()
    const prefixState = useSelector((state: ApplicationState) => state.prefix)
    const prefix = prefixState.info
    const [showMenuSupportLine, setShowMenuSupportLine] = useState<boolean>(false)
    const [openSupport, setOpenSupport] = useState<boolean>(false)
    const [openTermandconditionModal, setOpenTermandconditionModal] = useState<boolean>(false)
    const handleModalSupport = () => setOpenSupport(!openSupport)
    const termandconditionModal = () => setOpenTermandconditionModal(!openTermandconditionModal)

    const [checkTermandcondition, setCheckTermandcondition] = useState<boolean>(false)
    const [resetCounter, setResetCounter] = useState(0);

    const location = useLocation()
    const history = useHistory();

    const query = qs.parse(location.search.substr(1))
    const refCode = query.ref ? query.ref.toString() : ''

    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleSubmit = async () => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }

        const token = await executeRecaptcha('register');
        console.log('reCAPTCHA token:', token);

        // ส่ง token ไปตรวจสอบที่ backend ของคุณ
    };

    let autoBonus = 'off'
    if (refCode && prefix) {
        const promotion = prefix.promotions.find((v) => v.limit === 'once')
        if (promotion) {
            autoBonus = promotion.key
        }
    }

    // const [step, setStep] = useState<number>(1)
    const [stepOTP, setStepOTP] = useState<boolean>(false)
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [bankCode, setBankCode] = useState<string>('')
    // const [verifyRefOTP, setVerifyRefOTP] = useState<string>('')
    // const [verifyTransactionID, setVerifyTransactionID] = useState<string>('')
    // const [verifyTokenOTP, setVerifyTokenOTP] = useState<string>('')
    const [knowFrom, setKnowFrom] = useState<string>('')
    const [affiliateCode, setAffiliateCode] = useState<string>('')

    // const [tokenTurnstileOTP, setTokenTurnstileOTP] = useState<string>('')

    const handleTabs = (key: string) => {
        if (key === "login") {
            history.push(makePath('/login'))
        }
    };

    const itemTabs: TabsProps['items'] = [
        {
            key: 'login',
            label: 'เข้าสู่ระบบ',
        },
        {
            key: 'register',
            label: 'สมัครสมาชิก',
        },
    ];

    // const previousPageUrl = document.referrer;
    // const currentDomain = window.location.hostname;

    // const isFromDifferentDomain = previousPageUrl && !previousPageUrl.includes(currentDomain);
    // console.log(isFromDifferentDomain);
    // console.log(previousPageUrl);
    // console.log(currentDomain);

    const [showMenuSupport, setShowMenuSupport] = useState<boolean>(true)

    useEffect(() => {
        if (prefixState.info) {
            const isNullishSocialMediaLine = prefixState.info?.socialMedia ?
                (Object.keys(prefixState.info?.socialMedia).find((item: any) => item === "line") && prefixState.info?.socialMedia.line !== "" ? true : false)
                : false;
            setShowMenuSupportLine(isNullishSocialMediaLine)


            const isNullishSocialMedia = prefixState.info?.socialMedia ? Object.values(prefixState.info?.socialMedia).every(value => {
                if (value === "") {
                    return true;
                }
                return false;
            }) : true;
            setShowMenuSupport(isNullishSocialMedia)
        }
    }, [prefixState])

    useEffect(() => {
        if (form) {
            const params = new URLSearchParams(location.search);
            const getParamsRec: any = localStorage.getItem('rec')
            const getParamsRef: any = localStorage.getItem('ref')
            if (getParamsRef) {
                params.set('prefix', prefix?.prefix ? prefix?.prefix : '');
                params.set('ref', getParamsRef);
            }
            if (getParamsRec) {
                params.set('rec', getParamsRec);
            }
            history.push({ search: params.toString() });

            dataRegister.autoBonus = autoBonus
            dataRegister.knowFrom = getParamsRef || refCode !== "" ? 'friend' : ""
            dataRegister.affiliateCode = getParamsRef ? getParamsRef : refCode
            setAffiliateCode(getParamsRef ? getParamsRef : refCode)

            setKnowFrom(dataRegister.knowFrom)
            if (dataRegister.knowFrom) {
                form.setFieldsValue({ "knowFrom": dataRegister.knowFrom })
            }
        }
        return () => {

        }
    }, [form]) //eslint-disable-line

    useEffect(() => {
        // ทำงานเมื่อ component นี้ถูก mount
    }, [executeRecaptcha]);

    if (!prefix || (prefix?.functions.hasOwnProperty("offRegister") && prefixState?.info?.functions['offRegister'])) {
        return (
            <>
                <div style={{ textAlign: "center", marginTop: 20 }}><Text>ไม่สามารถสมัครสมาชิกได้ในขณะนี้</Text></div>
                {(prefix?.functions.hasOwnProperty("homePage") && prefix?.functions['homePage']) && (
                    <Link to={makePath('/home')}>
                        <div style={{ textAlign: 'center', padding: '8px 0', marginTop: 16 }}>
                            <Text><i className="fa-solid fa-house" style={{ fontSize: '18px', marginRight: 5 }}></i> กลับหน้าหลัก</Text>
                        </div>
                    </Link>
                )}
            </>
        )
    }


    const affiliateReadOnly = affiliateCode ? true : false

    const params = new URLSearchParams(location.search);
    const getParamsRec = params.get('rec');

    return (
        <>
            {false && <div className="register-main-slider"><MainSlider prefixState={prefixState} /></div>}
            <div className="form-card">
                <div className="form-card-body" style={{ paddingTop: 12, paddingBottom: 20 }}>
                    <Tabs defaultActiveKey="register" className="tab-auth" items={itemTabs} onTabClick={handleTabs} />
                    <Form
                        form={form}
                        name="registerForm"
                        layout="vertical"
                        style={{ marginTop: 0 }}>
                        <Form.Item label="เบอร์โทรศัพท์">
                            <Space.Compact style={{ width: '100%' }}>
                                <Form.Item
                                    name="phone"
                                    rules={[{ required: true, message: 'กรุณากรอกเบอร์โทรศัพท์' }]}
                                    style={{ marginBottom: 16 }}
                                    hasFeedback>
                                    <PatternFormat
                                        className="ant-input"
                                        format="###-###-####"
                                        mask="_"
                                        type="tel"
                                        placeholder="เบอร์โทรศัพท์"
                                    />
                                </Form.Item>
                                {/* {prefix?.functions.otp_register && ( */}
                                <Button onClick={() => setStepOTP(true)}>ขอ OTP</Button>
                                {/* )} */}
                            </Space.Compact>
                        </Form.Item>

                        <Form.Item
                            name="bankCode"
                            label="ธนาคาร"
                            rules={[{ required: true, message: 'กรุณาเลือกธนาคาร' }]}
                            style={{ marginBottom: 12 }}
                            hasFeedback>
                            <Select
                                placeholder="เลือกธนาคารของท่าน ..."
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input: any, option: any) =>
                                    (option?.label ?? '').includes(input)
                                }
                                onSelect={setBankCode} >
                                {
                                    Object.keys(bankNames).map((vBankCode) => (
                                        prefix.functions.truewalletRegister ? (
                                            <Select.Option key={vBankCode} value={vBankCode} label={bankNames[vBankCode]}>
                                                <Flex gap={'small'} align='center'>
                                                    <img
                                                        src={`/bank-logo/${(vBankCode).toLowerCase()}.svg`}
                                                        alt={vBankCode}
                                                        height={24}
                                                        width={24}
                                                    />
                                                    {bankNames[vBankCode]}
                                                </Flex>
                                            </Select.Option>
                                        ) : (
                                            vBankCode !== 'TRUEWALLET' && (
                                                <Select.Option key={vBankCode} value={vBankCode} label={bankNames[vBankCode]}>
                                                    <Flex gap={'small'} align='center'>
                                                        <img
                                                            src={`/bank-logo/${(vBankCode).toLowerCase()}.svg`}
                                                            alt={vBankCode}
                                                            height={24}
                                                            width={24}
                                                        />
                                                        {bankNames[vBankCode]}
                                                    </Flex>
                                                </Select.Option>
                                            )
                                        )
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="bankNumber"
                            label="เลขที่บัญชี"
                            rules={[{ required: true, message: 'กรุณากรอกเลขที่บัญชี' }, { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }]}
                            normalize={(value, prevVal, prevVals) => value.replace(/-/g, "").trim()}
                            style={{ marginBottom: 12 }}
                            hasFeedback>
                            <Input placeholder="เลขที่บัญชี" inputMode='numeric' />
                        </Form.Item>
                        {/* , { pattern: /^[ก-๙ ]+$/g, message: 'ชื่อต้องเป็นภาษาไทยเท่านั้น' } */}
                        <Form.Item
                            name="firstName"
                            label="ชื่อจริง"
                            rules={[{ required: true, message: 'กรุณากรอกชื่อจริง' }]}
                            style={{ marginBottom: 12 }}
                            hasFeedback>
                            <Input placeholder="ชื่อจริง" inputMode='text' />
                        </Form.Item>
                        {/* , { pattern: /^[ก-๙ ]+$/g, message: 'นามสกุลต้องเป็นภาษาไทยเท่านั้น' } */}
                        <Form.Item
                            name="lastName"
                            label="นามสกุล"
                            rules={[{ required: true, message: 'กรุณากรอกนามสกุล' }]}
                            style={{ marginBottom: 16 }}
                            hasFeedback>
                            <Input placeholder="นามสกุล" inputMode='text' />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            label="รหัสผ่าน (ตัวเลข 6 หลัก)"
                            rules={[
                                { required: true, message: 'กรุณากรอกรหัสผ่าน' },
                                { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }
                            ]}
                            style={{ marginBottom: 12 }}
                            hasFeedback>
                            <Input.Password placeholder="รหัสผ่าน (ตัวเลข 6 หลัก)" inputMode='numeric' maxLength={6} minLength={6} showCount />
                        </Form.Item>
                        <Form.Item
                            name="line"
                            label="ไอดีไลน์"
                            rules={[{ required: true, message: 'กรุณากรอกไอดีไลน์' }]}
                            style={{ marginBottom: 12 }}
                            hasFeedback>
                            <Input placeholder="ไอดีไลน์" inputMode='text' />
                        </Form.Item>
                        <Form.Item
                            name="autoBonus"
                            label="รับโบนัสอัตโนมัติ"
                            rules={[{ required: true, message: 'กรุณาเลือกรับโบนัสอัตโนมัติ' }]}
                            style={{ marginBottom: 12 }}
                            hasFeedback
                            initialValue={"off"}>
                            <Select
                                placeholder="เลือกรับโบนัสอัตโนมัติ"
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input: any, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }>
                                <Select.Option value="off">ไม่รับโบนัสอัตโนมัติ</Select.Option>
                                {prefix.promotions
                                    .filter((v) => v.amount > 0)
                                    .map((promotion) => (
                                        <Select.Option value={promotion.key} key={promotion.key}>
                                            {promotion.name}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Form.Item>
                        {!affiliateCode && (
                            <Form.Item
                                name="knowFrom"
                                label="ช่องทางที่รู้จักเรา"
                                rules={[{ required: true, message: 'กรุณาเลือกช่องทางที่รู้จักเรา' }]}
                                initialValue={getParamsRec}
                                style={{ marginBottom: 12, display: getParamsRec ? 'none' : undefined }}
                                hasFeedback>
                                <Select
                                    placeholder="เลือกช่องทางที่รู้จักเรา ..."
                                    showSearch
                                    disabled={getParamsRec ? true : false}
                                    optionFilterProp="children"
                                    onSelect={setKnowFrom}
                                    filterOption={(input: any, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    <Select.Option value="facebook" key="facebook">Facebook</Select.Option>
                                    <Select.Option value="twitter" key="twitter">Twitter</Select.Option>
                                    <Select.Option value="line" key="line">Line</Select.Option>
                                    <Select.Option value="google" key="google">Google</Select.Option>
                                    <Select.Option value="sms" key="sms">SMS</Select.Option>
                                    <Select.Option value="instargram" key="instargram">Instargram</Select.Option>
                                    <Select.Option value="youtube" key="youtube">Youtube</Select.Option>
                                    <Select.Option value="tiktok" key="tiktok">Tiktok</Select.Option>
                                    <Select.Option value="onlyfans" key="onlyfans">Onlyfans</Select.Option>
                                    <Select.Option value="telegram" key="telegram">Telegram</Select.Option>
                                    <Select.Option value="vk" key="vk">VK</Select.Option>
                                    <Select.Option value="friend" key="friend">เพื่อนแนะนำ</Select.Option>
                                    <Select.Option value="other" key="other">โฆษณาอื่น ๆ</Select.Option>
                                </Select>
                            </Form.Item>
                        )}
                        {affiliateCode && (
                            <Form.Item
                                name="knowFrom2"
                                label="ช่องทางที่รู้จักเรา"
                                rules={[{ required: true, message: 'กรุณาเลือกช่องทางที่รู้จักเรา' }]}
                                initialValue={getParamsRec}
                                style={{ marginBottom: 12, display: getParamsRec ? 'none' : undefined }}
                                hasFeedback>
                                <Select
                                    placeholder="เลือกช่องทางที่รู้จักเรา ..."
                                    showSearch
                                    disabled={getParamsRec ? true : false}
                                    optionFilterProp="children"
                                    filterOption={(input: any, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Select.Option value="facebook" key="facebook">Facebook</Select.Option>
                                    <Select.Option value="twitter" key="twitter">Twitter</Select.Option>
                                    <Select.Option value="line" key="line">Line</Select.Option>
                                    <Select.Option value="google" key="google">Google</Select.Option>
                                    <Select.Option value="sms" key="sms">SMS</Select.Option>
                                    <Select.Option value="instargram" key="instargram">Instargram</Select.Option>
                                    <Select.Option value="youtube" key="youtube">Youtube</Select.Option>
                                    <Select.Option value="tiktok" key="tiktok">Tiktok</Select.Option>
                                    <Select.Option value="onlyfans" key="onlyfans">Onlyfans</Select.Option>
                                    <Select.Option value="telegram" key="telegram">Telegram</Select.Option>
                                    <Select.Option value="vk" key="vk">VK</Select.Option>
                                    <Select.Option value="other" key="other">โฆษณาอื่น ๆ</Select.Option>
                                </Select>
                            </Form.Item>
                        )}

                        {knowFrom === "friend" && (
                            <Form.Item
                                name="affiliateCode"
                                label="รหัสชวนเพื่อน"
                                rules={[{ required: true, message: 'กรุณากรอกรหัสชวนเพื่อน' }]}
                                style={{ marginBottom: 12 }}
                                hasFeedback
                                initialValue={affiliateCode}>
                                <Input placeholder="รหัสชวนเพื่อน" inputMode='text' readOnly={affiliateReadOnly} />
                            </Form.Item>
                        )}

                        {prefix?.termandcondition?.termandconditionDescription && (
                            <div className="termandcondition-form-item">
                                <Checkbox onChange={(e: any) => setCheckTermandcondition(e)}>
                                    ยอมรับ
                                </Checkbox>
                                <span className="termandcondition" onClick={termandconditionModal}>เงื่อนไขและข้อตกลงในการใช้บริการ</span>
                            </div>
                        )}

                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={isSubmitting}
                            onClick={handleSubmit}
                            block
                        >
                            สมัครสมาชิก
                        </Button>
                    </Form>
                </div>
            </div>
            <ModalSupport isOpen={openSupport} onClose={() => setOpenSupport(false)} />
            <ModalTerms isOpen={openTermandconditionModal} onClose={() => setOpenTermandconditionModal(false)} description={prefixState.info?.termandcondition?.termandconditionDescription || ""} />
            <Modal

                className=""
                open={stepOTP}
                title="OTP"
                centered
                onCancel={() => setStepOTP(false)}
                footer={false}
            >
                <Form>
                    <Form.Item
                        name="otp"
                        rules={[
                            { required: true, message: "กรุณากรอก OTP" },
                            { len: 4, message: "OTP ต้องกรอกให้ครบ 4 หลัก" },
                        ]}
                    >
                        <Input.Group className="otp-input-group">
                            <Input
                                maxLength={1}
                                inputMode="numeric"
                                pattern="[0-9]*"
                            />
                        </Input.Group>
                    </Form.Item>
                </Form>
            </Modal>
            {(prefixState?.info?.functions.hasOwnProperty("homePage") && prefixState?.info?.functions['homePage']) && (
                <Link to={makePath('/home')}>
                    <div style={{ textAlign: 'center', padding: '8px 0', marginTop: 16 }}>
                        <Text><i className="fa-solid fa-house" style={{ fontSize: '18px', marginRight: 5 }}></i> กลับหน้าหลัก</Text>
                    </div>
                </Link>
            )}
        </>
    )
}

const Register = () => (
    <GoogleReCaptchaProvider reCaptchaKey={"6LeheC0qAAAAAI_vbMweDzRovuWAR9y77FgPkQQi"}>
        <RegisterNew />
    </GoogleReCaptchaProvider>
);

export default Register
